jQuery(function($){


	jQuery('.mylazy_menu').Lazy({
		effect: 'fadeIn',
		delay: 6000
	});

	$('.mylazy').Lazy({
		effect: 'fadeIn',
	});

	//
	$('.gotohref').click(function(){
		$('html, body').animate({
			scrollTop: $( $(this).attr('href') ).offset().top - 130
		}, 1200);
		//return false;
	});
	//
	$(".myDrag").niceScroll({
	cursorcolor:"#00F",
	cursorwidth:60,
	cursorcolor:'#6e8cb6',
	cursorborder:'none',
	cursorborderradius:0,
	cursorfixedheight: 130,
	autohidemode:false,
	railoffset: 200,
	//emulatetouch: true,
	//bouncescroll: true

  cursordragontouch: true
	 });	
	// 


if(!myVariables.wp_is_mobile) {
		var canvas = document.getElementById('canvasSmoke');
		if(canvas) {
			var ctx = canvas.getContext('2d')
			canvas.width = innerWidth
			canvas.height = innerHeight
	// 
			var party = smokemachine(ctx, [204, 204, 204])
			party.start() // start animating
			party.setPreDrawCallback(function(dt){
				party.addSmoke(innerWidth/2, innerHeight/2, .4)
				canvas.width = innerWidth
				canvas.height = innerHeight
			})
		}//canvas
}

	$('.close_blue').click(function() {
		jQuery( '.ubermenu' ).ubermenu( 'closeSubmenu' , jQuery( '#menu-item-164' ) );
	});

	$('.openKat').click(function(e) {
		e.preventDefault();
		jQuery( '.ubermenu' ).ubermenu( 'toggleMenuCollapse' );
		jQuery( '.ubermenu' ).ubermenu( 'openSubmenu' , jQuery( '#menu-item-164' ) );

		
});


	//

	
	function autoType(elementClass, typingSpeed){
		var thhis = $(elementClass);
		if(thhis) {
		thhis.css({
		  "position": "relative",
		  "display": "inline-block"
		});
		thhis = thhis.find(".text-js");
	   // console.log(thhis[0].innerHTML);
		var text = thhis.text().trim().split('');
		var amntOfChars = text.length;
		var newString = "";
		//thhis.text("|");
		thhis.text("");
		setTimeout(function(){
		  thhis.css("opacity",1);
		  thhis.prev().removeAttr("style");
		  thhis.text("");
		  for(var i = 0; i < amntOfChars; i++){
			(function(i,char){
			  setTimeout(function() {        
				newString += char;
				thhis.text(newString);
			  },i*typingSpeed);
			})(i+1,text[i]);
		  }
		},500);
		}
	  }
	  
	  autoType(".type-js",150);	
	//
	$('#navbar-content').on('hide.bs.collapse', function () {
		$('.navbar-toggler').removeClass('open');
	})
	$('#navbar-content').on('show.bs.collapse', function () {
		$('.navbar-toggler').addClass('open');
	})
	//
	myshrink();
	
	$(".Modern-Slider").slick({
		dots:false,
		appendDots:$('.wrapSliderHome'),
		arrows:true,
		prevArrow:'<img alt="" class="prevSlid" src="/wp-content/themes/theme/images/pag_left.svg" />',
		nextArrow:'<img alt="" class="nextSlid" src="/wp-content/themes/theme/images/pag_right.svg" />',		
		autoplay: true,
		autoplaySpeed:9000,
		speed:700,
		/*mobileFirst: true,*/
		slidesToShow:1,
		slidesToScroll:1,
		pauseOnHover:false,
		/*respondTo:'min',*/
		cssEase:'linear',
		fade:true,
	});
	
	$('.galeriaSlick').slick({
		dots: false,
		arrows:false,
		infinite: true,
		slidesToShow: 10,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		pauseOnHover: true,
		pauseOnFocus: true,
		
		cssEase: 'ease-out',
		responsive: [
			{
				breakpoint: 1700,
				settings: {
					slidesToShow: 8,
					slidesToScroll: 1,
				}
			},		
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			}
		]
	});
	
	/**/
	
	
	
});

jQuery(window).scroll(function() {
	myshrink();
});

jQuery(window).on("load",function($){
    window.sr = ScrollReveal({ reset: false, mobile: true });
	
	sr.reveal('.col_pod_slider_lista_right', { duration: 500, scale: 0.1},100);
	sr.reveal('.col_pod_slider_lista_left', { duration: 500, scale: 0.1},100);
	sr.reveal('.sekCelHome > .container', { duration: 1000, origin: 'bottom', distance: '150px'});
	sr.reveal('.blok_produkty_2_obrazek', { duration: 1000, origin: 'top', distance: '150px'});
	sr.reveal('.blok_produkty_2_opis', { duration: 1000, origin: 'bottom', distance: '150px'});


	sr.reveal('.animScrollReveal', { duration: 500, scale: 0.1});		
	

	sr.reveal('.colHomeTall', { duration: 500, scale: 0.1},200);
	sr.reveal('.deliverySection', { duration: 1000, origin: 'bottom', distance: '450px'});
	sr.reveal('.animLeft', { duration: 1000, origin: 'left', distance: '250px'});
	sr.reveal('.animRight', { duration: 1000, origin: 'right', distance: '250px'});
	sr.reveal('.sekcjaHome5', { duration: 1000, origin: 'bottom', distance: '250px'});
	sr.reveal('.animDown', { duration: 1000, origin: 'bottom', distance: '250px'});
	sr.reveal('.tekstyRep', { duration: 500, scale: 0.1, viewport:0.8},200);
	
	/**/
	
	var s = skrollr.init({
		forceHeight: false
	});
	if (s.isMobile()) {
		s.destroy();
	}
	
	if(true) {
		jQuery('#menu-item-34').addClass('current-menu-item');
		jQuery('.menu-item-34').addClass('current-menu-item');		
	}
});

function myshrink() {
	if (jQuery(document).scrollTop() > 50) {
		jQuery('.oberMenu').addClass('shrink');
		} else {
		jQuery('.oberMenu').removeClass('shrink');
	}	
}